<template>
  <div v-if="title" class="input-group mb-4" :style="style">
    <span :style="style" class="input-group-text" id="basic-addon1">{{ title }}</span>
    <div class="select2" :style="style">
      <multiselect 
        v-model="localValue" 
        :options="options" 
        placeholder="" 
        :allow-empty="props.clearable ? true : false"               
        selected-label=""
        select-label="" 
        track-by="value" 
        label="name" 
        :deselect-label="props.clearable ? 'clear' : ''" 
        object="false" 
        class="multiselect-blue"
        :disabled="disabled"
        :style="style"
        :preselectFirst="props.preselectFirst"
      />
    </div>
  </div>
  <div v-else :style="style">
    <div class="select2" :style="style">
      <multiselect 
        v-model="localValue" 
        :options="options" 
        placeholder="" 
        :allow-empty="props.clearable ? true : false"    
        selected-label=""
        select-label="" 
        track-by="value" 
        label="name" 
        :deselect-label="props.clearable ? 'clear' : ''"
        object="false" 
        class="multiselect-blue"
        :disabled="disabled"
        :style="style"
        :preselectFirst="props.preselectFirst"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';

const props = defineProps({
  modelValue: { type: [Object, String, Number, null], default: null },
  options: { type: Array, default: () => [] },
  disabled: { type: Boolean, default: false },
  title: { type: String, default: '' },
  style: { type: [String, Object], default: '' },
  onChange: { type: Function, default: null },
  preselectFirst: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
});

const emit = defineEmits(['update:modelValue']);

// Flag to prevent the watch on localValue from running when we're updating from props
const skipWatch = ref(false);
// Store the last value we emitted so we don't call onChange with the same value twice.
const lastEmitted = ref(null);

// The value bound to the multiselect component.
const localValue = ref(null);

// When the parent value changes, update localValue without triggering onChange.
watch(
  () => props.modelValue,
  (newValue) => {
    const newOption = props.options.find((option) => option.value === newValue) || null;
    // If the new option is the same as what we already have, don't update.
    if (localValue.value && newOption && localValue.value.value === newOption.value) {
      return;
    }
    skipWatch.value = true;
    localValue.value = newOption;
    skipWatch.value = false;
  },
  { immediate: true }
);

// When localValue changes (likely due to user interaction), emit the update and call onChange.
watch(
  localValue,
  (newValue, oldValue) => {
    if (skipWatch.value) return;
    const newVal = newValue ? newValue.value : null;
    const oldVal = oldValue ? oldValue.value : null;

    // If the new value is the same as what we last emitted or is identical to the previous value, do nothing.
    if (newVal === lastEmitted.value || newVal === oldVal) return;

    lastEmitted.value = newVal;
    emit('update:modelValue', newVal);
    if (typeof props.onChange === 'function') {
      props.onChange(newVal);
    }
  }
);
</script>

<style>
.multiselect__select {
  height: 46px;
}

.multiselect__tags {
  height: 54px;
  padding: 16px 0px 0 8px;
  border-radius: 1px;
  border: 1px solid #c7c7c7;
}
.multiselect__tags:hover {
  border: 1px solid #0045c4;
}

.multiselect__tags:focus {
  color: #4361ee;
}

.select2 .multiselect__option--highlight {
  background: #fff;
  color: #4361ee;
}

.select2 .multiselect__option--selected {
  background-color: rgba(27, 85, 226, 0.24);
  color: #4361ee;
  font-weight: normal;
}

.select2 .multiselect__option--disabled {
  background: inherit !important;
}

.select2 .multiselect__tag {
  color: #000;
  background: #e4e4e4;
}

.select2 .multiselect__tag-icon:after {
  color: #000 !important;
}

.select2-container .select2-selection--single {
  height: 55px;
  width: 100%;
  border: 1px solid #c5c5c5;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 54px;
  padding-left: 25px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 15px;
  border-color: #007bff;
}
</style>
